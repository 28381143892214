<!-- eslint-disable no-undef -->
<template>
    <div class="layout-default">
        <Header
            v-if="!isAskForm"
            v-model="isOpenMenu"
            :links="{
                anketa: linkToAnketa,
                landing: linkToLanding,
                lk: linkToLK,
            }"
            :is-login="false"
        />
        <main
            v-show="!isOpenMenu"
            class="layout-default__main"
        >
            <slot />
        </main>
        <div
            v-show="!isOpenMenu"
            class="layout__wrapper footer__wrapper"
        >
            <Footer class="layout-max976" />
        </div>
    </div>
</template>

<script>
import Header from "@frontend/hurma-main/components/header/header.vue";
import Footer from "@/components/footer/footer.vue";

export default {
    name: "LayoutDefault",
    components: {
        Header,
        Footer
    },
    data() {
        return {
            isOpenMenu: false,
        };
    },
    computed: {
        isAskForm(){
            return this.$route.name === "ask" || this.$route.name === "askResult"
        },
        linkToAnketa() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_ANKETA_URL;
        },
        linkToLanding() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_LANDING_URL;
        },
        linkToLK() {
            // eslint-disable-next-line no-undef
            return env.VUE_APP_LK_URL;
        },
    },
};
</script>